@import "~@/styles/variables/variables.scss";










































































































































































































































































































































































.alarm-record {
	@include base-button(80px);
	padding: 0 24px;
	height: 100%;
	display: flex;
	flex-direction: column;
	.filter {
		display: flex;
		justify-content: flex-start;
		align-items: center;
		margin: 20px 0 24px;
		.query {
			margin-left: 20px;
		}
		.search {
			margin-right: 20px;
		}
	}
	.table {
		flex: 1;
		height: 0;
	}
}
