@import "~@/styles/variables/variables.scss";


























































.config-info {
	padding: 24px;
	display: flex;
	flex-direction: column;
	height: 100%;
	.tab-box {
		padding-bottom: 20px;
		span {
			display: inline-block;
			width: 120px;
			text-align: center;
			line-height: 30px;
			border: 1px solid #6783ff;
			color: #4d6bff;
			cursor: pointer;
		}
		span:nth-child(1) {
			border-top-left-radius: 4px;
			border-bottom-left-radius: 4px;
		}
		span:nth-child(2) {
			border-left: none;
			border-top-right-radius: 4px;
			border-bottom-right-radius: 4px;
		}
		.active {
			background: #6783ff;
			color: #fff;
		}
		.border {
			border-radius: 4px;
		}
	}
	.table-box {
		flex: 1;
		height: 0;
	}
}
