@import "~@/styles/variables/variables.scss";




































































































































.content {
	height: 450px;
	@include flex-center;
	.echart {
		width: 100%;
		height: 100%;
	}
	.gc-blank-page {
		margin: auto;
	}
}
