@import "~@/styles/variables/variables.scss";





































































































































































































































































































































.directive-record {
	@include base-button(80px);
	padding: 0 24px;
	height: 100%;
	display: flex;
	flex-direction: column;
	.filter {
		display: flex;
		justify-content: flex-start;
		align-items: center;
		margin: 20px 0 24px;
		.query {
			margin-left: 20px;
		}
		.search {
			margin-right: 20px;
		}
	}
	.table {
		flex: 1;
		height: 0;
	}
}
.dialog-content {
	@include base-button(80px, 32px, 16px);
	.title {
		font-size: 14px;
		font-family: SourceHanSansCN-Medium, SourceHanSansCN;
		font-weight: 600;
		color: #222222;
		margin: 20px 0 10px;
	}
}
