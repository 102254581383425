@import "~@/styles/variables/variables.scss";




































































































































































.relation-device {
	@include base-button(120px);
	padding: 0 24px 24px 24px;
	height: 100%;
	display: flex;
	flex-direction: column;
	.filter {
		margin: 20px 0 24px;
		.add-relation-device {
			.el-button {
				border-color: #4d6bff;
				color: #4d6bff;
			}
		}
	}
	.table {
		flex: 1;
		height: 0;
	}
}
