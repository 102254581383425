@import "~@/styles/variables/variables.scss";




































































































































































































































































































































































































































































































































.chartTable {
	width: 100%;
	::v-deep .top {
		display: flex;
		justify-content: flex-start;
		align-items: center;
		margin-bottom: 50px;
		.date-box {
			width: 320px;
			margin-right: 16px;
			display: flex;
			justify-content: flex-start;
			align-items: center;
			border-radius: 4px;
			border: 1px solid #cccccc;
			.icon {
				width: 16px;
				height: 16px;
				margin-left: 10px;
			}
			.el-range-editor--small {
				border: 0;
				.el-range-separator {
					line-height: 26px;
					font-size: $base-font-size;
					color: #cccccc;
					padding: 0;
					width: 16px;
				}
				.el-range-input {
					width: calc(50% - 8px);
				}
				.el-range__close-icon,
				.el-icon-circle-close:before {
					display: none;
				}
				.el-range__icon {
					display: none;
				}
			}
		}
		.el-radio-button__inner {
			color: #4d6bff;
			border-color: #4d6bff;
			padding: 7px 15px !important;
			.el-radio-button:first-child & {
				border-color: #4d6bff;
			}
		}
		.el-radio-button__orig-radio:checked + .el-radio-button__inner {
			color: #ffffff;
			border-color: #4d6bff;
			// color: #e5662e #ffac05 #16a65d #5470c6 #91cc75;
		}
	}
	.content {
		height: 450px;
		.table,
		.echart {
			width: 100%;
			height: 100%;
		}
		.echart {
			overflow: hidden;
		}
	}
}
.date-box-popper-class {
	.el-picker-panel__sidebar {
		padding-top: 40px !important;
	}
}
::v-deep .el-table--border .el-table__cell .cell {
	padding-left: 60px;
}
