@import "~@/styles/variables/variables.scss";







































































































































































































































































































































































































































































































































































































.device-account-detail {
	@include base-button(100px);
	height: 100%;
	display: flex;
	.customer-info {
		height: 100%;
		padding: 12px;
	}
	.device-info {
		flex: 1;
		width: 0;
		display: flex;
		flex-direction: column;
	}
	.tab-box {
		flex: 1;
		height: 0;
	}
	.installed {
		background: #e4f5ec;
		color: #16a65d;
		&::before {
			position: absolute;
			top: 8px;
			left: 10px;
			display: block;
			content: '';
			width: 4px;
			height: 4px;
			border-radius: 50%;
			background-color: #16a65d;
		}
	}
	.wait-installed {
		background: #f5e9e4;
		color: #e5662e;
		&::before {
			position: absolute;
			top: 8px;
			left: 10px;
			display: block;
			content: '';
			width: 4px;
			height: 4px;
			border-radius: 50%;
			background-color: #e5662e;
		}
	}
	.had-uninstalled {
		background: #dbdbdb;
		color: #8f8f8f;
		&::before {
			position: absolute;
			top: 8px;
			left: 10px;
			display: block;
			content: '';
			width: 4px;
			height: 4px;
			border-radius: 50%;
			background-color: #8f8f8f;
		}
	}
}
