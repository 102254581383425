@import "~@/styles/variables/variables.scss";


















































































































































































































































.relation-device-form {
	display: flex;
	flex-wrap: wrap;
	.device,
	.pressure-point {
		width: 100%;
		display: flex;
		flex-wrap: wrap;
	}
	.el-form-item {
		width: 30%;
	}
	.el-form-item:not(:nth-child(3n + 1)) {
		margin-left: 40px;
	}
}
