@import "~@/styles/variables/variables.scss";

























































































































































































































































































































.basic-info {
	display: flex;
	justify-content: space-between;
	background-color: #fff;
	height: 100%;
	overflow: auto;
	.left {
		flex: 1;
		min-width: 889px;
		display: flex;
		flex-direction: column;
		padding-right: 40px;
		padding-left: 24px;
		.top {
			padding: 34px 0 40px;
			border-bottom: 1px dashed #999999;
			::v-deep [class*='el-col-'] {
				height: 44px;
			}
		}
		.bottom {
			padding-top: 43px;
		}
	}
	.right {
		flex: 1;
		max-width: 790px;
		padding-top: 30px;
		.card {
			display: flex;
			flex-wrap: wrap;
			align-content: flex-start;
		}
	}
	.title {
		display: flex;
		align-items: center;
		justify-content: space-between;
		font-size: 16px;
		font-family: SourceHanSansCN-Medium, SourceHanSansCN;
		font-weight: 600;
		color: #333333;
		padding-right: 40px;
		.refresh-time {
			font-size: 14px;
			font-family: SourceHanSansCN-Regular, SourceHanSansCN;
			font-weight: 400;
			color: #999999;
		}
	}
	.info-item {
		display: flex;
		justify-content: flex-start;
		align-items: center;
		width: 100%;
		margin-top: 20px;
		.label {
			font-size: 14px;
			font-family: SourceHanSansCN-Regular, SourceHanSansCN;
			font-weight: 400;
			color: #666666;
			flex-shrink: 0;
		}
		.value {
			flex: 1;
			width: 0;
			font-size: 14px;
			font-family: SourceHanSansCN-Regular, SourceHanSansCN;
			font-weight: 400;
			color: #333333;
		}
	}
	.install-pic {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		.pic-list {
			img {
				padding-top: 10px;
				vertical-align: top;
				width: 200px;
			}
			img + img {
				padding-left: 10px;
			}
		}
	}
}
