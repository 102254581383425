@import "~@/styles/variables/variables.scss";










































































































.status-card {
	position: relative;
	min-width: 238px;
	height: 100px;
	padding: 20px 20px 10px;
	margin: 20px 20px 0 0;
	border-radius: 6px;
	box-sizing: border-box;
	.title {
		display: flex;
		justify-content: flex-start;
		align-items: center;
		font-size: 16px;
		color: #4e4e4e;
		margin-bottom: 18px;
		.icon {
			width: 24px;
			height: 24px;
			margin-right: 8px;
		}
		.iconfont {
			font-size: 24px;
			width: 24px;
			height: 24px;
			margin-right: 8px;
		}
	}
	.value {
		font-family: DINAlternate-Bold, DINAlternate;
		font-size: 18px;
		font-weight: bold;
	}
	.status-card-icon {
		position: absolute;
		top: 0;
		right: 0;
		width: 32px;
		height: 32px;
		border-radius: 0 6px 0 0;
	}
}
::v-deep .el-dialog {
	.el-dialog__title {
		font-size: 20px;
		color: #4d4d4d;
	}
	.el-dialog__body {
		padding: 22px 25px 38px;
	}
}
