@import "~@/styles/variables/variables.scss";






















































































































































































.vee-table {
	height: 100%;
}
